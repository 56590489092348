import { enableProdMode, Injectable } from '@angular/core';

import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore/';
import {
  addDoc,
  Firestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  deleteDoc
} from '@angular/fire/firestore'
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private db: AngularFirestore, public firestore: Firestore,) {

  }

  admin: any
  email: any
  password: any
  numberOfOrders = ''
  numberOfPendingOrders = 0
  numberOfPendingDrivers = 0
  numberOfCanceledOrders = 0
  numberOfUsers = ''
  totalProfet = 0
  totalWallets = 0
  numberOfDrivers = ''
  lastDays: any = [];
  lastOrders: any = [];


  generateUniqueId() {
    const stringArr = [];
    for (let i = 0; i < 10; i++) {
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  saveUserData(email: any, password: any) {
    localStorage.setItem('email', email);
    this.email = localStorage.getItem('email')?.toString();
    localStorage.setItem('password', password);
    this.password = localStorage.getItem('password')?.toString();
  }

  getUserData() {
    this.email = localStorage.getItem('email')
    this.password = localStorage.getItem('password')
    console.log(this.email)
    console.log(this.password)
    return new Promise<boolean>((resolve, reject) => {
      if (localStorage.getItem('email') != 'null') {


        this.db.collection('users', ref => ref.where('email', '==', this.email).where('password', '==', this.password)).snapshotChanges()
          .subscribe(data => {

            var check = data.map(e => {
              return {
                id: e.payload.doc.id,
                data: e.payload.doc.data()

              };
            })
            console.log(check);
            if (check.length == 0) {
            } else {

              this.admin = check[0]
            }
            if (this.email == null) {
              resolve(false)
            } else
              resolve(true)
          });

      } else {
        resolve(false)
      }
    });

  }

  deleteUserData() {
    localStorage.setItem('email', "null");
    localStorage.setItem('password', "null");
    this.admin = 'null'
  }


  checkAvailabelPage(pageName: any) {
    if (this.admin.data.roles.admins == '1' && pageName == "admins") {
      return true;
    } else if (this.admin.data.roles.dashboard == '1' && pageName == "dashboard") {
      return true;
    } else if (this.admin.data.roles.users == '1' && pageName == "users") {
      return true;
    } else if (this.admin.data.roles.drivers == '1' && pageName == "drivers") {
      return true;
    } else if (this.admin.data.roles.carClasses == '1' && pageName == "carClasses") {
      return true;
    } else if (this.admin.data.roles.settings == '1' && pageName == "settings") {
      return true;
    } else if (this.admin.data.roles.payments == '1' && pageName == "payments") {
      return true;
    } else if (this.admin.data.roles.orders == '1' && pageName == "orders") {
      return true;
    }
    else if (this.admin.data.roles.promoCodes == '1' && pageName == "promoCodes") {
      return true;
    }
    else if (this.admin.data.roles.finance == '1' && pageName == "finance") {
      return true;
    } else if (this.admin.data.roles.areas == '1' && pageName == "areas") {
      return true;
    }
    else if (this.admin.data.roles.logs == '1' && pageName == "logs") {
      return true;
    }

    return false;
  }

  formatDateCustom(date: any) {

    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    if (dd < 10) { dd = '0' + dd }

    date = dd + '/' + mm;
    return date
  }

  replaceNumbers(value: any) {
    value = value.replace(/\٠/gi, '0');
    value = value.replace(/\١/gi, '1');
    value = value.replace(/\٢/gi, '2');
    value = value.replace(/\٣/gi, '3');
    value = value.replace(/\٤/gi, '4');
    value = value.replace(/\٥/gi, '5');
    value = value.replace(/\٦/gi, '6');
    value = value.replace(/\٧/gi, '7');
    value = value.replace(/\٨/gi, '8');
    value = value.replace(/\٩/gi, '9');

    return value;
  }


  generateUserId(count: any) {

    var char = "-";
    var alphapet = "D";
    var year = new Date().getFullYear().toString()
    var mounth = (new Date().getMonth() + 1).toString()
    return alphapet + char + year + mounth + char + count.toString();
  }
}
